import React, { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { Link, navigate } from "@reach/router"
import slugify from "slugify"
import { client } from "../../sanity"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/Community/Layout"
import { Flex, Title, Text, Grid, Box, Label } from "../../components/system"
import { GatsbyImage } from "gatsby-plugin-image"
import { Play } from "../../components/Icons"
import Loading from "../../components/Loading"

const Membership = () => {
  const [loading, setLoading] = useState(true)
  const [plan, setPlan] = useState({})
  const { user } = useAuth0()

  useEffect(() => {
    fetchUser()
  }, [])

  const fetchUser = async () => {
    const role = user["https://ilana.uk/roles"][0]
    const query = `*[_type == ${
      role === "Expert" ? '"expert"' : '"user"'
    } && uuid == $user]`

    const params = { user: user.sub }
    client
      .fetch(query, params)
      .then(user => {
        if (user[0].plan !== undefined) {
          setLoading(false)
          setPlan(user[0].plan)
        } else {
          navigate("/community/subscribe")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const data = useStaticQuery(graphql`
    query membership {
      sanityMembership {
        title
        message
        courses {
          title
          files {
            available
            _rawDescription
            exclusive
            title
            caption
            type
            thumbnail {
              asset {
                gatsbyImageData(aspectRatio: 1.5)
              }
            }
            file {
              asset {
                url
                originalFilename
              }
            }
          }
        }
      }
    }
  `)

  const { title, message, courses } = data.sanityMembership

  if (loading) {
    return <Loading size={10} />
  } else {
    return (
      <Layout>
        <Flex flexDirection="column" py={[4]}>
          <Label fontSize={1} color="faded">
            Ilana Membership
          </Label>
          <Title fontSize={[6, 7]} color="secondary">
            {title}
          </Title>
          <Text maxWidth={600} px={3}>
            {message}
          </Text>
          <Link to="/community/membership/manage" state={{ plan: plan }}>
            <Flex border="1px solid black" p={2} px={3} borderRadius={30}>
              <Text m={0}>Manage Subscription -></Text>
            </Flex>
          </Link>
        </Flex>

        {courses.map(course => (
          <Box key={course.title} m={[3, 5]}>
            <Title textAlign="left" fontSize={[5, 6]} color="primary">
              {course.title}
            </Title>
            <Grid
              gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
              gridGap={4}
            >
              {course.files.map(file => (
                <Link
                  to={
                    file.available
                      ? "/community/membership/"
                      : `/community/content/${slugify(file.title, {
                          lower: true,
                        })}`
                  }
                  state={{ data: file, plan: plan }}
                  key={file.title}
                >
                  <Box>
                    <Flex position="relative">
                      <GatsbyImage
                        image={file.thumbnail.asset.gatsbyImageData}
                        alt={file.title}
                        imgStyle={{ opacity: file.exclusive ? 0.3 : 1 }}
                      />
                      {file.type === "Video" && (
                        <Flex
                          bg="white"
                          size={50}
                          borderRadius={25}
                          position="absolute"
                        >
                          <Play size={15} />
                        </Flex>
                      )}
                      {file.available ? (
                        <Box
                          py={1}
                          px={2}
                          borderRadius={3}
                          position="absolute"
                          top={15}
                          right={15}
                          bg="secondary"
                          color="white"
                        >
                          <Label down>Coming Soon...</Label>
                        </Box>
                      ) : (
                        file.exclusive && (
                          <Box
                            py={1}
                            px={2}
                            borderRadius={3}
                            position="absolute"
                            top={15}
                            right={15}
                            bg="secondary"
                            color="white"
                          >
                            <Label down>Available on Plus +</Label>
                          </Box>
                        )
                      )}
                    </Flex>
                    <Text textAlign="left" pt={3} mb={1}>
                      {file.title}
                    </Text>
                    {file.caption && (
                      <Text textAlign="left" color="faded">
                        {file.caption}
                      </Text>
                    )}
                  </Box>
                </Link>
              ))}
            </Grid>
          </Box>
        ))}
      </Layout>
    )
  }
}

export default Membership
